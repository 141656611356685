import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import constants from '../../constants';
import { prefectures } from '../../constants/address';
import Chips from '../Chips';
import MoreButton from '../MoreButton';

const maxSize = 20;

const ShrineRoll = props => {
  const { data } = props;

  let shrines = data.allMarkdownRemark.edges.map(item => {
    const targetArea = prefectures.find(
      prefecture => prefecture.id === item.node.frontmatter.area
    );
    return {
      id: item.node.frontmatter.area,
      label: targetArea.name,
      slug: item.node.fields.slug,
      date: item.node.frontmatter.date,
      name: item.node.frontmatter.name,
      address: item.node.frontmatter.address,
      tags: item.node.frontmatter.tags,
      blessings: item.node.frontmatter.blessings,
      featuredimage: item.node.frontmatter.featuredimage,
    };
  });

  let selectedFilters = {};
  let filteredShrines = [];
  let basePath = '';

  switch (props.type) {
    case constants.articleTypes.area:
      basePath = `/shrine-area/${props.value}`;
      selectedFilters = {
        ids: [props.value],
      };
      const { ids } = selectedFilters;
      filteredShrines = shrines.filter(({ id }) =>
        ids.includes(id.toLowerCase())
      );
      break;
    case constants.articleTypes.tag:
      basePath = `/shrine-tag/${props.value}`;
      filteredShrines = shrines.filter(({ tags }) => {
        return tags.find(item => item === props.value);
      });
      break;
    case constants.articleTypes.blessing:
      basePath = `/shrine-blessings/${props.value}`;
      filteredShrines = shrines.filter(({ blessings }) => {
        if (!blessings) {
          return null;
        }
        return blessings.find(item => item === props.value);
      });
      break;
    default:
      basePath = '/shrine';
      filteredShrines = shrines;
      break;
  }

  return (
    <>
      <ul className="list">
        {filteredShrines.map((item, i) => {
          if (maxSize < i) {
            return null;
          }
          return (
            <li className="listItem listItem--shrine" key={i}>
              <Link to={item.slug}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: item.featuredimage,
                    alt: `${item.name}`,
                  }}
                />
              </Link>
              <div className="listItem__body">
                <div className="listItem__address">{item.address}</div>
                <div className="listItem__name">{item.name}</div>
                <Chips
                  type={constants.articleTypes.blessing}
                  values={item.blessings}
                />
              </div>
            </li>
          );
        })}
      </ul>
      {filteredShrines.length > maxSize ? (
        <MoreButton basePath={basePath} />
      ) : null}
    </>
  );
};

ShrineRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default ShrineRoll;
