import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import BlogRoll from '../BlogRoll';
import ShrineRoll from '../ShrineRoll';
import constants from '../../constants';
import './style.sass';

const PaginatedRoll = props => {
  const { type } = props;
  const { currentPage, numPages, basePath, title } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  const getRoll = type => {
    switch (type) {
      case constants.articleTypes.blog:
        return <BlogRoll data={props.data} />;

      case constants.articleTypes.shrine:
        return <ShrineRoll data={props.data} />;

      default:
        return null;
    }
  };

  const getBathPath = path => {
    switch (path) {
      case constants.basePath.blog:
        return `${path}`;
      case constants.basePath.blogArea:
        return `${path}/${title}`;
      case constants.basePath.blogTag:
        return `${path}/${title}`;
      case constants.basePath.blogShrine:
        return `${path}/${title}`;
      case constants.basePath.blogBlessings:
        return `${path}/${title}`;
      case constants.basePath.shrine:
        return `${path}`;
      case constants.basePath.shrineArea:
        return `${path}/${title}`;
      case constants.basePath.shrineTag:
        return `${path}/${title}`;
      case constants.basePath.shrineBlessings:
        return `${path}/${title}`;
      case constants.basePath.tags:
        return `${path}/${title}`;
      case constants.basePath.products:
        return `${path}`;
      case constants.basePath.areas:
        return `${path}`;
      case constants.basePath.blessings:
        return `${path}`;
      default:
        return null;
    }
  };

  return (
    <section>
      {getRoll(constants.articleTypes[type])}
      <div className="paginatedRoll">
        {!isFirst && (
          <Link
            className="paginatedRoll__button paginatedRoll__button--prev"
            to={`${getBathPath(basePath)}/${prevPage}`}
            rel="prev"
          >
            戻る
          </Link>
        )}
        {!isLast && (
          <Link
            className="paginatedRoll__button paginatedRoll__button--next"
            to={`${getBathPath(basePath)}/${nextPage}`}
            rel="next"
          >
            次へ
          </Link>
        )}
      </div>
    </section>
  );
};

PaginatedRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default PaginatedRoll;
