import React from 'react';
import { Link } from 'gatsby';
import Image from '../Image';
import './style.sass';

export default props => {
  const { basePath } = props;
  return (
    <Link className="moreButton" to={`/${basePath}/2`}>
      <Image filename="/buttons/button_more.png" />
    </Link>
  );
};
