import React from 'react';
import { Link } from 'gatsby';
import constants from '../../constants';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import Chips from '../Chips';
import MoreButton from '../MoreButton';
import './style.sass';

const maxSize = 20;

export default props => {
  const { data } = props;

  let blogs = data.allMarkdownRemark.edges.map(item => {
    return {
      id: item.node.frontmatter.area,
      title: item.node.frontmatter.title,
      slug: item.node.fields.slug,
      date: item.node.frontmatter.date,
      shrines: item.node.frontmatter.shrines,
      tags: item.node.frontmatter.tags,
      blessings: item.node.frontmatter.blessings,
      featuredimage: item.node.frontmatter.featuredimage,
    };
  });

  let selectedFilters = {};
  let filteredBlogs = [];
  let basePath = '';

  switch (props.type) {
    case constants.articleTypes.area:
      basePath = `/blog-area/${props.value}`;
      selectedFilters = {
        ids: [props.value],
      };
      const { ids } = selectedFilters;
      filteredBlogs = blogs.filter(({ id }) => ids.includes(id.toLowerCase()));
      break;
    case constants.articleTypes.shrine:
      basePath = `/blog-shrine/${props.value}`;
      filteredBlogs = blogs.filter(({ shrines }) => {
        return shrines.find(item => item === props.value);
      });
      break;
    case constants.articleTypes.tag:
      basePath = `/blog-tag/${props.value}`;
      filteredBlogs = blogs.filter(({ tags }) => {
        return tags.find(item => item === props.value);
      });
      break;
    case constants.articleTypes.blessing:
      basePath = `/blog-blessings/${props.value}`;
      filteredBlogs = blogs.filter(({ blessings }) => {
        if (!blessings) {
          return null;
        }
        return blessings.find(item => item === props.value);
      });
      break;
    default:
      basePath = '/blog';
      filteredBlogs = blogs;
      break;
  }

  return (
    <>
      <ul className="blogList">
        {filteredBlogs.map((item, i) => {
          return (
            <li key={i}>
              <Link to={item.slug} className="blogList__item">
                <div className="blogListItem__thumb">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: item.featuredimage,
                      alt: `${item.title.replace(/ *\[[^)]*\] */g, '')}`,
                    }}
                  />
                </div>
                <div className="blogListItem__content">
                  <div className="blogListItem__title">{`${item.title.replace(
                    / *\[[^)]*\] */g,
                    ''
                  )}`}</div>
                  <div className="blogListItem__date">{item.date}</div>
                  <Chips type={constants.articleTypes.tag} values={item.tags} />
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
      {filteredBlogs.length > maxSize ? (
        <MoreButton basePath={basePath} />
      ) : null}
    </>
  );
};
