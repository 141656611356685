import React from 'react';
import { navigate } from 'gatsby';
import constants from '../../constants';
import './style.sass';

export default props => {
  const { values, type } = props;

  const getPath = type => {
    switch (type) {
      case constants.articleTypes.blessing:
        return '/blessings';
      case constants.articleTypes.tag:
        return '/tags';
      default:
        return '/';
    }
  };

  const handleClick = e => {
    e.preventDefault();
    const path = `${getPath(type)}/${e.currentTarget.textContent}`;
    navigate(path);
  };

  return (
    <div className="chips">
      {values &&
        values.map((item, i) => {
          return (
            <div key={i} className="chip" onClick={handleClick}>
              {item}
            </div>
          );
        })}
    </div>
  );
};
